import React from 'react';
// import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { Container, Row } from 'react-grid-system';
// import Title from 'components/title';
import Component from '@reach/component-component';
import { Dialog } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import BgImage from 'assets/images/work/clutter/clutter-dots-pink.png';
import ModalThumb from 'assets/images/work/clutter/clutter.jpg';
import ModalImage01 from 'assets/images/work/clutter/clutter-1.jpg';
import ModalImage02 from 'assets/images/work/clutter/clutter-blog.jpg';

export const Wrapper = styled.div`
  margin-top: -4rem;
  ${MEDIA.TABLET`
    display: block;
  `};
`;

export const SectionWrapper = styled.section`
  .section__img-inner {
    background-image: url(${BgImage});
    background-position: 50% 0%;
    background-size: auto;
  }
  ${MEDIA.TABLET`
    display: block;
    .section__img-inner {
      background-image: url(${BgImage});
      background-position: 0% 0%;
    }
  `};
`;

const ImageModal = () => (
  <Component initialState={{ showDialog: false }}>
    {({ state, setState }) => (
      <div>
        <button onClick={() => setState({ showDialog: true })}>
          <img src={ModalThumb} alt="Clutter Magazine Thumbnail" />
        </button>

        <Dialog
          isOpen={state.showDialog}
          onDismiss={() => setState({ showDialog: false })}
        >
          <button
            className="close-button"
            onClick={() => setState({ showDialog: false })}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>×</span>
          </button>
          <p style={{ width: '100%' }}>
            <img
              style={{ width: '100%', marginBottom: '1rem' }}
              src={ModalImage01}
              alt="Clutter Modal01"
            />
            <img
              style={{ width: '100%', marginBottom: '1rem' }}
              src={ModalImage02}
              alt="Clutter Modal02"
            />
          </p>
        </Dialog>
      </div>
    )}
  </Component>
);

const Section = () => (
  <SectionWrapper className="section section--current">
    <div className="section__content">
      <h1 className="section__title">Clutter</h1>
      <p className="section__description">
        <span className="section__description-inner">
          The website is an ecommerce enabled news site, with over 10,000 pages
          of content. Our ecommerce platform allows us to not only take orders
          via our webstore. but also allows us to wholesale via the site and via
          our back end system, allowing us to email links to customers to pay
          for invoices. Pretty Sweet!
        </span>
      </p>
    </div>
    <div className="section__img">
      <div className="section__img-inner" />
    </div>
    <div className="section__more">
      <div className="section__more-inner section__more-inner--bg1">
        <span className="section__more-text">Want to know more?</span>
        <a href="/work" className="section__more-link">
          <span className="section__more-linktext">Explore all projects</span>
        </a>
      </div>
    </div>
    <ul className="section__facts">
      <li
        className="section__facts-item section__facts-item--clickable"
        data-gallery="gallery1"
      >
        <div className="section__facts-img">
          <ImageModal />
        </div>
        <h2 className="section__facts-title">Clutter Media Group</h2>
      </li>
      <li className="section__facts-item">
        <h3 className="section__facts-title">Website</h3>
        <span className="section__facts-detail">
          Web Development, E-Commerce, CMS
        </span>
      </li>
    </ul>
  </SectionWrapper>
);

const Alice = () => (
  <Layout>
    <Container fluid>
      <Row>
        <Wrapper className="sections">
          {/* <Fact /> */}
          <Section />
        </Wrapper>
      </Row>
    </Container>
  </Layout>
);

export default Alice;
